import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Bananes Citronnees - Table des matières" />
    <h2>{data.allBooksJson.nodes[0].book} suivies de Caprices</h2>
    <br />
    <h3 className='underline-title'>Table des matières</h3>
    <br />
    <ol>
      {data.allBooksJson.nodes[0].pageInfo.map(({ node }, index) => (
        <li key={index}>
          <Link to={ node.link } className='nav-link'>{ node.poemTitle }</Link>
        </li>
      ))}  
    </ol>
  </Layout>
)

export const query = graphql`
  query {
    allBooksJson {
      nodes {
        book
        pages
        link
        pageInfo {
          node {
            link
            poemTitle
          }
        }
      }
    }
  }
`
